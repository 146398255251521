import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  useTheme,
  useMediaQuery,
  Divider,
  Typography,
} from '@mui/material';
import RuleTerms from '../RuleTerms';
import SocialIcons from '../SocialIcons';
import { ReactComponent as LogoMobi } from 'assets/logo-mobiauto-white.svg';
import Logo from 'assets/icons/passe_carros_logo';
import { DefaultTheme } from 'styles/theme/defaultTheme';

import Tibi from 'assets/tibi_logo.png';
import TooSeguros from 'assets/too_seguros.png';

const Footer = () => {
  const theme = useTheme<DefaultTheme>();
  const desktop = useMediaQuery(theme.breakpoints.up('md'));

  const currentYear = new Date().getFullYear();

  return (
    <>
      <Box component="footer" bgcolor={theme.palette.secondary.main}>
        <Container maxWidth="lg">
          <Box
            component="div"
            display="flex"
            justifyContent="center"
            alignItems="center"
            flexDirection={{ xs: 'column', md: 'row' }}
            margin="0 auto"
            textAlign="center"
            width="100%"
            gap="24px"
            py={{ xs: 2, md: 5 }}
          >
            <LogoMobi />

            <Divider
              variant="fullWidth"
              orientation="vertical"
              sx={
                desktop
                  ? {
                      width: '1px !important',
                      height: '52px',
                      backgroundColor: '#fff',
                    }
                  : { width: '100%', height: '1px', backgroundColor: '#fff' }
              }
            />
            <Box display="flex" alignItems="center" gap="8px" color="#fff">
              <Typography fontSize="12px">powered by</Typography>
              <Logo color="#fff" />
            </Box>
            {/* <SocialIcons gap="16px" /> */}
          </Box>
        </Container>
        <Box bgcolor="#ffffff33" color="#fff">
          <Box
            maxWidth="1280px"
            display="flex"
            justifyContent="space-between"
            m="0 auto"
            p={2}
            flexDirection={{ xs: 'column', md: 'row' }}
          >
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              pb={{ xs: 2, md: 0 }}
            >
              <RuleTerms
                gap={{ xs: '16px', md: '40px' }}
                flexDirection={{ xs: 'column', md: 'row' }}
                color="#fff"
              />
            </Box>
            {!desktop && <Divider variant="fullWidth" />}
            <Box
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              alignItems={{ xs: 'flex-start', md: 'center' }}
              pt={{ xs: 2, md: 0 }}
            >
              <Box mr={{ xs: '0', md: '16px' }} fontSize="14px">
                © {currentYear} Todos os direitos reservados.
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Footer;
