import styled from '@emotion/styled';
import LogoPasseCarros from 'assets/mobiauto_logo.svg';

const AlignCenter = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100vh;
`;

const LoadingFullScreen = () => {
  return (
    <AlignCenter>
      <img src={LogoPasseCarros} alt="loading..." width="300px" />
    </AlignCenter>
  );
};
export default LoadingFullScreen;
