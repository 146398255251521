import React from 'react';
import {
  NavLink as NavRouterLink,
  Link as RouterLink,
  useNavigate,
  useLocation,
} from 'react-router-dom';
import { useSelector } from 'react-redux';

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import useMediaQuery from '@mui/material/useMediaQuery';
import { Avatar, List, ListItem, useTheme } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LaunchIcon from '@mui/icons-material/Launch';
import { CloseOutlined, Logout } from '@mui/icons-material';

import { ReactComponent as OfficeStampDocument } from 'assets/office_stamp_document.svg';
import EditSquare from 'assets/icons/editSquare';

import useModal from 'hooks/useModal';
import UserInfo from 'components/atoms/UserInfo';
import { ReactComponent as Logo } from 'assets/mobiauto_logo.svg';
import { DefaultTheme } from 'styles/theme/defaultTheme';
import { logout } from 'auth/AuthService';
import Button from 'components/atoms/Button/Button';

const navLinks = [
  {
    path: '/passe-carros',
    label: 'Ofertas',
    end: false,
  },
  {
    path: '/passe-carros/minha-conta',
    label: 'Minha conta',
    end: false,
    icon: EditSquare,
  },
];

const navLinksMobile = [
  {
    path: '/passe-carros',
    label: 'Ofertas',
    end: false,
  },
  {
    path: '/passe-carros/minhas-propostas',
    label: 'Minhas Propostas',
    end: false,
  },
  {
    path: '/passe-carros/minha-conta',
    label: 'Minha conta',
    end: false,
    icon: EditSquare,
  },
  {
    path: '/passe-carros/regulamento',
    label: 'Regulamento',
    end: false,
    icon: OfficeStampDocument,
    iconEnd: LaunchIcon,
  },
];

const Header: React.FC = () => {
  const theme = useTheme<DefaultTheme>();
  const desktop = useMediaQuery(theme.breakpoints.up('sm'));
  const navigate = useNavigate();
  const location = useLocation();
  const currentUser = useSelector((state: any) => state.userStore?.currentUser);

  const selectModalActions = useModal();

  const [open, setOpen] = React.useState(false);

  const NavLink = React.forwardRef((props: any, ref: any) => (
    <div ref={ref}>
      <NavRouterLink
        {...props}
        className={({ isActive }) =>
          `${props.className} ${isActive ? props.activeClassName : undefined}`
        }
        style={{ color: 'inherit' }}
      />
    </div>
  ));

  return (
    <>
      <Box component="header" bgcolor="#FFF">
        <Container maxWidth="lg">
          <Box
            component="div"
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            height={60}
            sx={{
              '& > a': {
                lineHeight: '0',
              },
            }}
          >
            <Link component={RouterLink} to="/passe-carros">
              <Logo />
            </Link>
            {desktop ? (
              <>
                <Box component="ul" display="flex" alignItems="center">
                  {navLinks.map(({ label, path, end }) => (
                    <List aria-label="Main List" key={label}>
                      <ListItem component={NavLink} to={path} end>
                        <Box
                          component="span"
                          fontWeight={700}
                          fontSize="14px"
                          color={
                            path === location?.pathname
                              ? 'primary.main'
                              : 'inherit'
                          }
                        >
                          {label}
                        </Box>
                      </ListItem>
                    </List>
                  ))}
                </Box>
                <Box
                  component="div"
                  display="flex"
                  alignItems="center"
                  style={{ gap: 16 }}
                >
                  <Button
                    variant="secondary"
                    onClick={() => navigate('/passe-carros/minhas-propostas')}
                  >
                    Minhas propostas
                  </Button>
                  <UserInfo
                    open={open}
                    selectModalActions={selectModalActions}
                  />
                </Box>
              </>
            ) : (
              <Box component="div" display="flex" alignItems="center" gap="8px">
                <MenuIcon
                  onClick={() => {
                    setOpen(!open);
                  }}
                />
              </Box>
            )}
          </Box>
        </Container>
      </Box>
      {!desktop && open && (
        <Box
          component="nav"
          position="fixed"
          height="100vh"
          width="100%"
          top={0}
          bgcolor="#fff"
          zIndex={99999999}
        >
          <Container maxWidth="lg">
            <Box
              component="div"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
              height="60px"
            >
              <Box display="flex" alignItems="center">
                <Avatar alt="User">
                  {currentUser?.firstName?.slice(0, 1)}
                </Avatar>
                <Box display="flex" flexDirection="column" ml={2}>
                  <Box
                    component="span"
                    fontSize="12px"
                    lineHeight="14px"
                    fontWeight={400}
                  >
                    Olá,
                  </Box>
                  <Box
                    component="p"
                    fontWeight={700}
                    fontSize="14px"
                    lineHeight="20px"
                  >
                    {currentUser?.firstName}
                  </Box>
                </Box>
              </Box>
              <Button
                type="text"
                color="inherit"
                aria-describedby="user-menu"
                onClick={() => setOpen(false)}
              >
                <CloseOutlined />
              </Button>
            </Box>
            <Box
              component="div"
              display="flex"
              flexDirection="column"
              gap="16px"
              mt={2}
            >
              {navLinksMobile.map(
                ({ label, path, icon: Icon, iconEnd: IconEnd }) => (
                  <Box
                    color="#2D2D2D"
                    component="span"
                    fontSize="16px"
                    fontWeight={500}
                    key={label}
                    style={{ listStyle: 'none' }}
                    onClick={() => setOpen(false)}
                  >
                    <Link
                      component={RouterLink}
                      to={path}
                      color="inherit"
                      display="flex"
                      alignItems="center"
                    >
                      {Icon && (
                        <Box lineHeight={0} mr={1}>
                          <Icon />
                        </Box>
                      )}
                      {label}
                      {IconEnd && (
                        <Box
                          height="fit-content"
                          lineHeight={0}
                          mr={0}
                          ml={'auto'}
                        >
                          <IconEnd />
                        </Box>
                      )}
                    </Link>
                  </Box>
                ),
              )}
            </Box>
            <Button
              type="text"
              color="inherit"
              sx={{
                padding: 0,
                fontSize: '16px',
              }}
              onClick={() => logout()}
              startIcon={<Logout fontSize="medium" />}
            >
              Sair
            </Button>
          </Container>
        </Box>
      )}
      {/* {selectModalActions.isOpen ? (
        <DealersModal selectModalActions={selectModalActions} />
      ) : null} */}
    </>
  );
};

export default Header;
